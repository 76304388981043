import i18n from 'i18next';
import LanguageDetector from  'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from '../locale/en';
import bg from '../locale/bg';

const resources = {
  bg: {
    translation: bg
  },
  en: {
    translation: en
  }
};
const DETECTION_OPTIONS = {
  order: ['localStorage', 'path', 'navigator'],
  caches: ['localStorage'],
  lookupFromPathIndex: 0,
      checkWhitelist: true
};


i18n 
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
  detection: DETECTION_OPTIONS,
  resources,
  whitelist: ['bg', 'en'],
  fallbackLng: 'en',
  supportedLngs: ['bg', 'en'],
  
  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;